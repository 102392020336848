import React, { useRef, useEffect } from 'react';
import { Container } from '@hpstellar/core';
import axios from 'axios';

import { StoreAppAPI } from '../../core/src/storeapp-api-client';
import withPageComponents from '../../page/components/with-page-components';
import DependencyLoader from '../../page/components/script-loader/dependency-loader';
import useDynamicPriceHtml from '../../hooks/useDynamicPriceHtml';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import { Helpers } from '../../core';

const CONTAINER_ID = 'features';

const MDPRichContent = ({ mdpRichContent, isBot }) => {
    const rcRef = useRef(null);
    useDynamicPriceHtml([rcRef, mdpRichContent], { timeout: 1000, maxAttempts: 10 });
    const handleClick = e => {
        //handle URLs in rich content with weird tab targeting parameters that is not supported by react router
        try {
            const { target } = e;
            const { tagName } = target;
            const href = target.getAttribute('href') || '';
            if (tagName === 'A' && href.indexOf(window.location.pathname) > -1) {
                const tabId = href.match(/tab\=.*/)[0].split('=')[1];
                document.getElementById(tabId).click();
            }
        } catch (e) {}
    };
    useEffect(() => {
        if (!mdpRichContent) return;

        const richContentString = mdpRichContent.join('');
        let cleanedRichContent = richContentString.replaceAll(
            /<link.*(?:.*(mdp_foundation|watch-slick-theme|mdpBaseStyles).*)>/g,
            '',
        );

        //TODO: replace with better regex if possible
        const list = [...cleanedRichContent.matchAll(/<link.*(?:.*)>/g)];
        const styleTags = list.map(item => [...item?.[0].match(/href="[^\"]*(?=")/g)]?.[0]);
        const links = styleTags.map(s => s && s.replace('href="', ''));

        cleanedRichContent = richContentString.replaceAll(/<link.*(?:.*)>/g, '').replaceAll('.fv5 ', '.mdp-fv5 .fv5 ');
        const proms = links.map(l => axios.get(l));

        async function setContent() {
            await Promise.allSettled(proms).then(values => {
                const styles = values.map(v => {
                    if (!v || v?.status === 'rejected') {
                        const storeAppAPI = new StoreAppAPI();
                        storeAppAPI.logger.log({
                            message: `MDP asset loading error`,
                            info: v?.reason,
                            level: 'error',
                        });

                        return '';
                    }
                    return `<style scoped>${v?.value?.data?.replaceAll('.fv5', '.mdp-fv5 .fv5')}</style>`;
                });
                const output = document.createRange().createContextualFragment(styles.join('') + cleanedRichContent);

                rcRef.current.innerHTML = '';
                rcRef.current.appendChild(output);
            });
        }
        setContent();

        //TODO: hack to get scripts to trigger correctly
        const DELAY = 1000;
        const foundationTimeout = setTimeout(() => {
            $(document).foundation();

            //NOTE: mdp/laptops/hp-z-display-294032--1 work around for incorrect element heights
            if (window?.location?.pathname?.includes('mdp/laptops/hp-z-display-294032--1')) {
                $('.iBottom ul').css('height', '300px');
                $('.iBottom h4').css('height', '110px');
                $('.iBottom p').css('height', '140px');
            }
        }, DELAY);

        // trigger foundation rendering with exponential backoff
        let currentTimeout;
        const scheduleInitWithBackoff = start => {
            currentTimeout = setTimeout(() => {
                $(document).foundation();
                scheduleInitWithBackoff(start * 2);
            }, 1000 * start);
        };
        scheduleInitWithBackoff(1);

        return () => {
            clearTimeout(currentTimeout);
            clearTimeout(foundationTimeout);
        };
    }, [mdpRichContent]);

    if (!mdpRichContent || mdpRichContent.length < 1) {
        return null;
    }

    return (
        <div className={'mdp-rich-content mdp-fv5'} id={CONTAINER_ID} onClick={handleClick}>
            {/* TODO wont work should we just do skip render all together for ssr? */}
            {/* <AboveTheFoldOnlyServerRender skip={true} offset={1000}> */}
            <Container>
                <div ref={rcRef} dangerouslySetInnerHTML={isBot ? Helpers.createMarkup(mdpRichContent) : null} />
            </Container>
            {/* </AboveTheFoldOnlyServerRender> */}
        </div>
    );
};
export default withPageComponents(
    ({ mdpRichContent }) => {
        const { assets, content } = mdpRichContent || {};
        const { js, css } = assets || {};
        const { isBot } = useDeviceInfo(['isBot']);
        if (!content || content.length < 1) {
            return null;
        }
        return (
            <DependencyLoader
                js={js}
                css={css}
                skip={isBot}
                placeholder={<div id={CONTAINER_ID} style={{ minHeight: '500px' }} />}
            >
                <MDPRichContent mdpRichContent={content} isBot={isBot} />
            </DependencyLoader>
        );
    },
    { components: ['mdpRichContent'] },
);
