import React from 'react';

import ProductTile from '../../product/components/product-tile';
import withPageComponents from '../../page/components/with-page-components';
import useAddToCart from '../../hooks/useAddToCart';
import useProductCompare from '../../hooks/useProductCompare';
import getProductProps from '../../product/get-product-props';
import useProductPrice from '../../hooks/useProductPrice';
import TitleSection from '../../shared/components/title-section';
import useProductImpressions from '../../hooks/useProductImpressionsV2';
import useCartState from '../../hooks/useCartState';
import { ColorSelectorContainer } from '../../hooks/useColorSelector';

const DEFAULT_LAZYLOAD_SETTINGS = {
    robots: true,
    skip: true,
    offset: 800,
};

const MdpProductList = ({ mdpProductList, mdpHeader, analyticsData, lazyLoadSettings = DEFAULT_LAZYLOAD_SETTINGS }) => {
    const { products = [], sectionTitle: title } = mdpProductList || {};
    const { prices } = useProductPrice(products, { useSubscriptionsPromo: true });
    const addToCart = useAddToCart(true);
    const compare = useProductCompare();
    const { cartId } = useCartState();

    let { page_level } = analyticsData || {};
   const productsWithAnalytics = useProductImpressions({
        products,
        list: page_level,
        componentKey: 'mdpProductList',
    });

    if (productsWithAnalytics && productsWithAnalytics.length > 0) {
        return (
            <div id="buy">
                <TitleSection title={title} partnerLogoProps={mdpHeader} typographyVariant={'titleM'} />
                <div className="mdpList">
                    {productsWithAnalytics.map((product, idx) => {
                        const { sku } = product;
                        return (
                            <ColorSelectorContainer key={`${sku}-${idx}`} product={product}>
                                {({ colorSelection }) => (
                                    <ProductTile
                                        {...getProductProps(
                                            product,
                                            prices[sku],
                                            { addToCart, compare, colorSelection },
                                            { resizeImage: true, titleTag: 'h2', hasPriceSeoText: true },
                                            { list: 'mdp', position: idx + 1, cartId }
                                        )}
                                        isFull
                                        className="tile"
                                        ratingSpaceReserved={true}
                                        lazyLoadSettings={idx > 2 && lazyLoadSettings}
                                    />
                                )}
                            </ColorSelectorContainer>
                        );
                    })}
                </div>
            </div>
        );
    }
    return null;
};

export default withPageComponents(MdpProductList, { components: ['mdpProductList', 'mdpHeader'], analyticsData: true });
