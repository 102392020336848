import React, { useState } from 'react';
import { Tabs } from '@hpstellar/core';
import { useSmoothScroll } from '@hpstellar/core/hooks';

import withPageComponents from '../../../page/components/with-page-components';
import useScrollTarget from '../../../hooks/useScrollTarget';
import { withError } from '../../../shared/components/error-boundary';

const MIN_HEIGHT = 90;
import './sticky-nav.less';
const LIST = [
    {
        tab: {
            gtmValue: 'buy',
            title: 'Buy',
            hash: 'buy',
            id: 'vao',
        },
    },
    {
        tab: {
            gtmValue: 'features',
            title: 'Features',
            hash: 'features',
            id: 'fea',
        },
    },
];

/**
 * Adds hidden elements to help support rich content that has clicks targeted ids from legacy MDP pages
 */
const ScrollClickTargets = withError(({ list = [], onClick = () => {} }) => {
    return (
        <div style={{ display: 'none' }}>
            {list.map(({ tab }, idx) => {
                return (
                    <div
                        id={tab.id}
                        onClick={() => {
                            onClick(idx);
                        }}
                    ></div>
                );
            })}
        </div>
    );
});

const handleTabClick = scrollToTarget => index => {
    const { tab } = LIST[index] || {};
    if (tab.hash) {
        try {
            scrollToTarget(document.getElementById(tab.hash));
        } catch (e) {}
    }
};

const StickyNAV = ({ mdpProductList, mdpRichContent, width }) => {
    const { scrollToTarget } = useSmoothScroll({ offset: MIN_HEIGHT });
    const [activeIndex, setActiveLink] = useState(0);
    useScrollTarget(
        'buy',
        undefined,
        lastKnownScrollPosition => {
            let index = 0;
            LIST.forEach(({ tab }, idx) => {
                let anchorElem = document.getElementById(tab.hash);
                if (anchorElem && lastKnownScrollPosition > anchorElem.offsetTop) {
                    index = idx;
                }
            });
            setActiveLink(index);
        },
        MIN_HEIGHT * 2
    );
    const { products } = mdpProductList || {};
    const { content: richContent } = mdpRichContent || {};
    if ((products || []).length < 1 || (richContent || []).length < 1) {
        return null;
    }

    return (
        <div className="mdp-nav">
            <ScrollClickTargets list={LIST} onClick={handleTabClick(scrollToTarget)} />
            <Tabs
                list={LIST}
                activeIndex={activeIndex}
                tabsListProps={{
                    type: 'secondary',
                    size: width < 1024 ? 'small' : 'large',
                    onClick: handleTabClick(scrollToTarget),
                    dropdownOnMobile: false,
                    tabTitleTag: 'h3',
                }}
            />
        </div>
    );
};

export default withPageComponents(StickyNAV, {
    components: ['mdpProductList', 'mdpRichContent'],
    device: true,
});
