import React from 'react';

import MDPProductList from './mdp-product';
import CompareDrawer from '../../compare/components/compare-drawer';
import MDPRichContent from './mdp-rich-content';
import MobilePartnerLogo from './mdp-mobile-logos';
import MDPStickyNAV from './sticky-nav';
import SuppliesFinder from './mdp-supplies-search';

import './mdp.less';
import './rich-content.less';

const MDP = ({ vanityUrl }) => {
    /** this wrapping div was a fragment, but for some reason the client graphql fallback did not like that **/
    return (
        <div>
            <div className={'mdp'}>
                <SuppliesFinder />
                <MDPStickyNAV />
                <MDPProductList />
                <MDPRichContent />
                <MobilePartnerLogo />
            </div>
            <CompareDrawer />
        </div>
    );
};

export default MDP;
